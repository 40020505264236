<template>
  <div class="no-scrollbar text-shadow" style="white-space:nowrap;font-size:14px;margin:0;overflow:auto;opacity: 0.8;text-align: center;">
    <ul class="list-unstyle">
      <template v-if="hasGroup()">
        <template v-for="(item,index) in groups">
          <li v-if="!(getgroup(item).length==0 && !item)" class="GroupItem" style="">
            <div style="position:relative;cursor:pointer;" @click="$emit('selectGroup',item)">
              <div class="text-truncate group" :class="{'active':groupName==item.id}" style="text-align:center;font-size:10px;">
                <a style="color:#fff;">
                  {{item.title||'公共空间'}}
                </a>
              </div>
            </div>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        //groups: []
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      rooms() {
        return this.publicData.rooms || {}
      },
      inn() {
        return this.publicData.inn || {}
      },
      tour() {
        return this.publicData.tour || {}
      },
      groupName() {
        return this.publicData.groupName
      },
      groups() {
        var groups = this.tour.groups || []
        var g
        for (var i in this.tour.items) {
          var item = this.tour.items[i]
          //if (!item.groupName) {
          //    continue
          //}
          if (!(g = this.isInGroup(item.roomId, groups))) {
            g = this.newGroup(item.roomId, this.rooms[item.roomId])
            groups.push(g)
          }
          item.groupGUID = g.guid
        }
        return groups
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          changeGroup: this.changeGroup,
          getGroup: this.getGroup,
        },
        events: {
          selectGroup: {
            target: 'groups',
            name: 'changeGroup'
          }
        }
      })
      //this.getFunc('changePublicData')({ groupItems: this.getgroup(this.groupName) })
    },
    destroyed() {
    },
    methods: {
      getGroup(groupName) {
        console.log(groupName, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].title == groupName) {
            return this.groups[i]
          }
        }
      },
      changeGroup(item) {
        console.log('changeGroup', item)
        var groupItems = this.getFunc({ target: 'Data', name: 'getgroup' })(item.id)
        this.getFunc('changePublicData')({ groupName: item.id, groupItems, })
        if (groupItems && groupItems[0]) {
          this.routerto(groupItems[0])
        }
      },
      routerto(item, obj) {
        this.$router.push({ query: { senceId: item.guid, from: this.$route.query.from, ...obj } })
      },
      hasGroup() {
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      getgroup(groupName) {
        return this.getFunc({ target: 'Data', name: 'getgroup' })(groupName) || []
      },
      newGroup(groupName, data) {
        return {
          title: groupName,
          description: '',
          audioAutoPlay: true,
          audioLoop: false,
          file: null,
          audio: null,
          ...data
        }
      },
      isInGroup(groupName, groups) {
        for (var i in groups) {
          if (groups[i].id == groupName) {
            return groups[i]
          }
        }
        return false
      }
    },
  }
</script>
<style scoped>
  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }
</style>
